





















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import QueryPanel from '../components/QueryPanel.vue'

const { mapGetters } = createNamespacedHelpers('Queries')

export default Vue.extend({
  components: {
    QueryPanel
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['error'])
  },
  methods: {
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('Queries/load')
    ])
  }
})
